import React from "react";

import "./WhyFlutter.scss";

import * as Icons from '../icons';

import heroImage from "../../../assets/imgs/services/flutter-dev-background.png";

import {SectionTitle} from "../shared/SectionTitle/SectionTitle";
import {IconVariants, ItemsGrid} from "../shared/ItemsGrid/ItemsGrid";
import {Intro} from "../shared/Intro/Intro";
import {handleGetInTouch, Hero} from "../shared/Hero/Hero";
import {OurApplications} from "../shared/OurApplications/OurApplications";
import {TwoColumnsContent, TwoColumnsContentVariants} from "../shared/TwoColumnsContent/TwoColumnsContent";
import {TestimonialSlider} from "../../TestimonialSlider/TestimonialSlider";
import {ContactUs} from "../shared/ContactUs/ContactUs";
import {Process} from "../shared/Process/Process";
import {ServicesBlogPosts} from "../shared/ServicesBlogPosts/ServicesBlogPosts";
import {ContinueReading} from "../../ContinueReading/ContinueReading";
import {Companies} from "./Companies/Companies";

export const WhyFlutter = ({posts}) => (
    <div className={"why-flutter"}>
            <Hero title={<h1>Your <span>Flutter</span> cross-platform mobile applications.</h1>} subtitle={<>Beautiful, fast, reliable <br/> Your Flutter cross-platform mobile applications, created by experts.</>} heroImage={heroImage}/>
            <Intro
                sectionTitle={<SectionTitle number={1} title={"What is Flutter"} isBorderBottom={true}/>}
                leftColumn={[
                    <>Flutter is a framework that allows you to build apps for iOS, Android, Windows, Mac, Linux, Google Fuchsia, and the web <b>using a single codebase.</b></>,
                    <div className={"icon"}><Icons.GreyOctagon/><Icons.FlutterIcon/></div>
                ]}
                rightColumn={[
                    <div className={"content"}>Flutter makes it possible to build cost-effective, cross-platform applications that meet the highest design, reliability, and performance expectations.</div>,
                    <div className={"content"}>Flutter keeps its reputation as cutting-edge technology due to its efficiency advantage over competitors React Native, Xamarin, and IONIC. Flutter also outperforms native Android and iOS apps in time and cost savings. Flutter is a fast-growing technology that’s becoming more sophisticated as more companies and developers adopt it.</div>
                ]}
            />
            <ItemsGrid
                items={[
                    {'title': 'Great performance', 'desc': '120fps easily matches the performance of native applications', 'icon': <Icons.Chart/>},
                    {'title': 'Fast development', 'desc': 'Development is up to 40% quicker than native technologies', 'icon': <Icons.Code/>},
                    {'title': 'Customisable UI', 'desc': 'UI creation that offers unlimited possibilities', 'icon': <Icons.Lego/>},
                    {'title': 'Cost-efficient app maintenance', 'desc': 'Up to 50% less costly than using native technologies', 'icon': <Icons.Coins/>},
                    {'title': 'Single development team', 'desc': 'One team is responsible for both iOS and Android apps', 'icon': <Icons.Person/>},
                    {'title': 'Secure', 'desc': 'Complies with the highest security standards', 'icon': <Icons.FlagTypeThing/>}
                ]}
                sectionTitle={<SectionTitle number={2} title={"Flutter Benefits"}/>}
                iconVariant={IconVariants.OCTAGON}
            />
            {/* <Companies/> */}
            <OurApplications sectionTitle={<SectionTitle number={3} title={"Our Flutter applications"} isBorderBottom={true}/>}/>
            <ItemsGrid
                items={[
                    {'title': 'Development is fast', 'desc': 'Flutter\'s hot reload means building and debugging applications is faster than with other frameworks.', 'icon': <Icons.CodeRed/>},
                    {'title': 'Cross-platform design is simple', 'desc': 'Once designed, visual assets are used across all the platforms you target. Every component looks the same, including buttons, drop-downs, animations, and so on.', 'icon': <Icons.LayersRed/>},
                    {'title': 'Performance is great', 'desc': 'The 120 fps speed is equal to native Android and iOS apps and much faster than other cross-platform technologies.', 'icon': <Icons.ChartRed/>},
                    {'title': 'Endless UI and animation possibilities', 'desc': 'Flutter renders each visual element you create, which means the possibilities for UI style and movement are endless.', 'icon': <Icons.LegoRed/>},
                        {'title': 'Supported by Google and trusted by big companies', 'desc': 'Flutter\'s growth and progress are important to Google. Flutter is used by a variety of major corporations, including Microsoft and Toyota.', 'icon': <Icons.FlagRed/>},
                    {'title': 'App release and publication is easy', 'desc': 'Release new features on all platforms without extra effort. The single code base gives you a smoother development process. For example, the Android team does not need to wait on the iOS team before an update gets released.', 'icon': <Icons.WindowUpRed/>}
                ]}
                sectionTitle={<SectionTitle number={4} title={"Why should I choose Flutter?"}/>}
                iconVariant={IconVariants.CIRCLE}
            />
            <div className={"white-bg flutter-experts"}>
                <div className={"sections"}>
                    <h2>Looking for flutter experts?</h2>
                    <button className="button-red" onClick={handleGetInTouch}>LET'S WORK TOGETHER</button>
                </div>
            </div>
            <ItemsGrid
                items={[
                    {'title': 'Secure', 'desc': 'Data storage and privacy, cryptography, authentication, and session management are included in the set of security standards developed by OWASP. Flutter satisfies these criteria, making it ideal for high-security applications like banking and medicine.', 'icon': <Icons.FlagTypeThingRed/>},
                    {'title': 'Only one development team', 'desc': 'With a single code base, there\'s no need for separate teams for Android, iOS and web. Instead of two or more teams, recruit only one!', 'icon': <Icons.PersonRed/>},
                    {'title': 'Developers are available', 'desc': 'Developers like Flutter as it boosts their productivity. Today, there’s no trouble finding engineers to work with as the number specialising in Flutter continues to grow.', 'icon': <Icons.PieChartRed/>},
                    {'title': 'Lower cost of app maintenance', 'desc': 'Debugging is much faster when using a single code base. Testers and developers\' lives are easier as bugs appear the same way on all platforms, so they are easier to identify and fix.', 'icon': <Icons.HandRed/>},
                    {'title': 'Be ready for Fuchsia OS', 'desc': 'Flutter is compatible with Fuchsia OS, a new Google platform. As a result, a Flutter application will run on Fuchsia OS with little effort.', 'icon': <Icons.WindowCheckRed/>},
                    {'title': 'A devoted community', 'desc': 'Flutter has been stable since 2017. Today, it has 111k GitHub stars, which means many thousands of developers trust it. React has 30k fewer stars even though it\'s been around longer. Flutter\'s biggest competitor, React Native, has been stable since 2015. Today, it has 93k GitHub stars.', 'icon': <Icons.NetworkRed/>},
                ]}
                sectionTitle={null}
                iconVariant={IconVariants.CIRCLE}
            />
            <TwoColumnsContent
                sectionTitle={<SectionTitle title={'What problems does it solve'} number={5} isBorderBottom={true}/>}
                variant={TwoColumnsContentVariants.BLACK}
                items={[
                    {title: 'hiring engineers', content: 'No need to hire separate engineering teams, one for Android and another for iOS', image: <Icons.PersonAtDesk/>},
                    {title: 'one codebase', content: 'No more communication and team alignment problems as only one codebase used', image: <Icons.TwoPeople/>},
                    {title: 'performance', content: 'No more no more drop in performance when developing cross-platform apps', image: <Icons.PhoneAndChart/>},
                    {title: 'budget', content: 'No difficulties building prototypes and MVPs on a limited budget', image: <Icons.PersonAndChart/>}
                ]}
            />
            <TwoColumnsContent
                sectionTitle={<SectionTitle title={'What projects is it good for?'} number={6} isBorderBottom={true}/>}
                variant={TwoColumnsContentVariants.WHITE}
                items={[
                    {title: 'Interactive prototypes', content: 'In less than two weeks, you can build a clickable prototype with Flutter. It not only saves you time and money but also allows you to confirm technical feasibility and get customer/investor feedback earlier.', image: <Icons.PersonAndChart2/>},
                    {title: 'MVP (Minimum Viable Product)', content: 'A single development team and unified app designs make Flutter an excellent choice for building product MVPs. Quick, cost-efficient development means your product will be market-ready fast. Win your first clients and investors sooner!', image: <Icons.TwoPhones/>},
                    {title: 'Animation-rich applications', content: 'Skia, Flutter\'s built-in engine, renders visual elements frame by frame. As a result, Flutter is highly-efficient and an excellent choice for graphically-intensive apps.', image: <Icons.Browser/>},
                    {title: 'Long-term projects', content: 'Flutter is suitable for scalable, long-term projects due to its excellent performance, a single code base, reduced team size, and an easy-to-learn programming language.', image: <Icons.TwoPeopleAndChart/>}
                ]}
            />
            <section className="testimonial-slider white-bg color-black border-grey">
                <div className={"sections"}>
                    <TestimonialSlider page="index" title={"Our team about Flutter"}/>
                </div>
            </section>
            <ContactUs title={"Do you want to discuss your mobile app project with an expert?"} buttonText={"Contact us"}/>
            <ItemsGrid
                items={[
                    {'title': '15 years', 'desc': 'of commercial experience', 'icon': <Icons.BriefcaseRed/>},
                    {'title': '12 years', 'desc': 'building scalable applications with millions of users', 'icon': <Icons.BriefcaseRed/>},
                    {'title': '6 years', 'desc': 'experience in secure fintech projects', 'icon': <Icons.BooksRed/>},
                    {'title': '4 years', 'desc': 'developing medical and health applications', 'icon': <Icons.HeartRed/>},
                    {'title': 'PCI DSS / HIPAA', 'desc': 'Experience with GDPR, PCI DSS and HIPAA security compliance', 'icon': <Icons.GlobusRed/>},
                    {'title': 'mentoring', 'desc': 'Built mobile applications for big corporations and brand-new startups', 'icon': <Icons.HandsRed/>},
                    {'title': 'Details', 'desc': 'Great attention to details', 'icon': <Icons.EyeRed/>},
                    {'title': 'Product team', 'desc': 'UX and UI matters to us', 'icon': <Icons.PencilRed/>},
                    {'title': '10 days', 'desc': 'Have a rapid prototype built in as little as 10 days!', 'icon': <Icons.WindowsRed/>},
                ]}
                sectionTitle={<SectionTitle number={7} title={"Why Allbright"}/>}
                iconVariant={IconVariants.OCTAGON_TRANSPARENT}
            />
            <Process sectionTitle={<SectionTitle number={8} title={"Flutter Application Development Process"}/>}/>
            <section className="testimonial-slider white-bg color-black border-grey">
                <div className={"sections"}>
                    <TestimonialSlider page="index" title={"Clients about us"}/>
                </div>
            </section>
            <ContactUs title={"Want to talk to a Flutter expert? Contact us!"} buttonText={"Get in touch"}/>
            <ServicesBlogPosts posts={posts}/>
            <div className={"sections"} style={{paddingBottom: "0"}}>
                <ContinueReading nextSection="React Native" link="React Native" />
            </div>
        </div>
);