import React from "react";
import Seo from "../../../components/Seo/seo";
import {WhyFlutter} from "../../../components/Services/WhyFlutter/WhyFlutter";
import {graphql} from "gatsby";

function FlutterAppDevelopment({data}) {
    let posts = []
    data.allMdx.edges.forEach(x => posts.push({
        categories: x.node.frontmatter.categories,
        title: x.node.frontmatter.title,
        abstract: x.node.frontmatter.abstract,
        imageSource: x.node.frontmatter.imageSource.childImageSharp.fluid.src,
        link: x.node.frontmatter.link,
        date: x.node.frontmatter.date,
    }))


    posts = posts.sort(function (a, b) {
        var aa = a.date.split('/').reverse().join(),
            bb = b.date.split('/').reverse().join();
        return aa > bb ? -1 : (aa < bb ? 1 : 0);
    }).filter(x => x.categories.includes("mobile applications flutter") || x.categories.includes("flutter"));

    return (
        <>
            <Seo title={"Why Flutter?"} />
            <WhyFlutter posts={posts}/>
        </>
    );
}

export default FlutterAppDevelopment;

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            abstract
            categories
            link
            date
            imageSource {
              childImageSharp {
                fluid(quality: 99) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          } 
        }
      }
    }
  }
`;